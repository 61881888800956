<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import CardIconLabelLocal from '@/components/CardIconLabelLocal'
import moment from 'moment'
import { Button, DatePicker } from 'ant-design-vue'
import FormUploadSku from '@/components/FormView/FormUploadSku'
import Ticket from '@/views/com/Ticket'
import Naves from '@/views/com/Naves'
import { thirdTypeDataList } from '@/utils/textFile'

export default {
  name: 'scenicAreasTicketDetail',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 0,
      labelGroup: [],
      detail: {
        skuList: [
          {
            skuImgUrl: '',
            skuName: null,
            originalPrice: null,
            salePrice: null,
            jsPrice: null,
            allStock: null,
            sort: null,
          },
        ],
        canBuy: '1',
        refundExplain: '0',
        recommend: '0',
        thirdTicket: '0',
        changeTicket: '0',
        joinFx: '0',
        hxExpireType: '1',
        thirdTypeName: '',
        hxStartTime: '',
        hxEndTime: '',
        preDay: '',
        refundRate: '',
        saleCount: '',
        readCount: '',
        buyCount: '',
        useAfterDay: '',
        useAfterHour: '',
        refundExplain: '1',
      },
      isShowSnapshot: false,
      storeData: [],
      typeSearchData: [],
      thirdTypeSearchData: [],
      classifyList: [],
      productInfo: {},
      returnSet: [],
    }
  },
  watch: {
    'detail.linkShopId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    },
  },
  mounted() {
    // getAction('/admin/dict/code/ticket_type', {}, '/bpi').then(res => {
    //   let { data } = res
    //   this.thirdTypeSearchData = data.map(e => {
    //     return {
    //       name: e.name,
    //       value: e.id
    //     }
    //   })
    // })
    const { id, shopId, scenicId } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/goods/farmTicket/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.linkShopName)
          this.detail = {
            ...res,
            realNameFlag: !res.realNameFlag ? [] : ['0'].includes(res.realNameFlag) ? [] : res.realNameFlag.split(','),
            realNameInput: res.realNameInput ? res.realNameInput.split(',') : [],
          }
          this.$forceUpdate()
        })
    } else {
      this.detail.linkShopId = shopId
      this.detail.scenicId = scenicId
      this.storeSearch('')
    }

    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'scenic_label',
          },
        ],
      },
    })
  },
  methods: {
    /** 数据查询 */
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    linkShopMenuClick(data) {
      if (this.detail.linkShopId !== data.id) {
        this.detail.productTypeName = ''
        this.detail.productType = ''
      }
    },
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
      this.detail.linkShopName = this.storeData.filter((e) => e.value === this.detail.linkShopId)[0].name
      this.$forceUpdate()
    },
    async typeSearch(value = '') {
      if (!this.detail.linkShopId) {
        this.$message.warning('请选择关联店铺!')
        return
      }
      const searchResult = await api.command.search.call(this, {
        url: `/api/farmTicketType/list?shopId=${this.detail.linkShopId}&name=${value}`,
      })
      this.typeSearchData = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    /** end 数据查询 */

    //#region 基础设置
    getFormPz() {
      return {
        title: '配置',
        type: 'cardForm',
        data: [
          {
            name: '加入分销商品',
            type: 'switchOpen',
            cols: 2,
            key: 'joinFx',
            showText: ['开', '关'],
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 4,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '第三方票务',
            type: 'switchOpen',
            cols: 2,
            key: 'thirdTicket',
            showText: ['开', '关'],
          },

          ...this.tabFormPz(),
        ],
      }
    },
    tabFormPz() {
      if (this.detail.thirdTicket == '0') {
        return []
      } else {
        return [
          {
            name: '票务平台',
            type: 'select',
            key: 'thirdPlatform',
            cols: 3,
            typeData: [
              {
                name: '自我游',
                value: '0',
              },
              {
                name: '云上游',
                value: '1',
              },
            ],
          },
          {
            name: '票务种类',
            type: 'row',
            rules: [
              {
                required: true,
              },
            ],
            children: [
              {
                label: '票务种类',
                type: 'input',
                cols: 22,
                key: 'thirdProductName',
                disabled: true,
                placeholder: '请点击后面按钮选择种类',
                rules: [
                  {
                    required: true,
                  },
                ],
              },
              {
                type: 'button',
                cols: 2,
                props: {
                  icon: 'menu',
                  type: 'primary',
                  on: {
                    click: () => {
                      this.showTicketKind()
                    },
                  },
                },
              },
            ],
          },
        ]
      }
    },
    showTicketKind() {
      // if (!this.detail.thirdPlatform) {
      //   this.$message.error('请先选择票务平台')
      //   return
      // }
      apiTool.showModal({
        width: '50vw',
        title: '选择票务种类',
        view: Ticket,
        viewProps: {
          // ticketType: this.detail.thirdPlatform,
          productIdArr: [],
        },
        success: ({ data, setHidden }) => {
          const { productNo, productName, id } = data
          this.detail.thirdProductId = productNo
          this.detail.thirdProductName = productName
          getAction('/ticket/productInfo', { id }, '/api').then((rs) => {
            const { data } = rs
            this.productInfo = data
            this.detail.bannerUrl = data.img && data.img
            this.handleData(data)
          })
          setHidden()
          this.$forceUpdate()
        },
        foot: () => [],
        // foot: ({ close }) => {
        //   return <Button onClick={close}>关闭</Button>
        // }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'productName',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShopName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.linkShopMenuClick,
            disabled: true,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          ...[
            {
              name: '票种',
              type: 'select',
              cols: 12,
              key: 'thirdType',
              rules: [
                {
                  required: true,
                },
              ],
              display: this.detail.thirdTicket == '0',
              onChange: (val) => {
                this.detail.thirdTypeName = thirdTypeDataList().find((f) => f.value == val)?.name || ''
                this.$forceUpdate()
              },
            },
            {
              name: '适用人群',
              type: 'input',
              cols: 12,
              placeholder: '例如：1.2m~1.5m的儿童',
              key: 'scopeDesc',
              display: this.detail.thirdTicket == '0',
            },
            {
              name: '分类',
              type: 'row',
              cols: 12,
              gutter: 16,
              rules: [
                {
                  required: true,
                },
              ],
              children: [
                {
                  label: '分类',
                  type: 'dropDownInput',
                  cols: 20,
                  key: 'productTypeName',
                  onInputSearch: this.typeSearch,
                  valueKey: 'productType',
                  inputProps: {
                    placeholder: '选择分类',
                    addonBefore: <a-icon type="search" />,
                  },
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
                {
                  type: 'button',
                  cols: 4,
                  label: '管理分类',
                  props: {
                    on: {
                      click: () => {
                        if (this.detail.linkShopName && this.detail.linkShopId) {
                          getAction('/farmTicketType/list', { shopId: this.detail.linkShopId }, '/api').then((res) => {
                            let { data } = res
                            this.classifyList = data
                            this.showManageClassify(data)
                          })
                        } else {
                          this.$message.warning('请先选择关联店铺')
                        }
                      },
                    },
                  },
                },
              ],
              display: true,
            },
            {
              name: '最大可预订天数',
              type: 'inputNumber',
              cols: 5,
              key: 'preDay',
              placeholder: '例如：5（用户可以预定5天内商品）',
              display: this.detail.thirdTicket == '0',
              props: {
                min: 0,
              },
            },
            {
              cols: 7,
              type: 'text',
              value: `
            <div style="margin-top: 38px">
                <span style="color:#515A6E;font-size:14px">天之前的日期</span>&nbsp;<span style="color:#aaa;font-size:14px">就算之后的日期设置有库存也不展示和支持预订。</span>
            </div>`,
              display: this.detail.thirdTicket == '0',
            },
          ].filter((f) => f.display),
          ...this.getType(),
        ],
      }
    },
    getType() {
      return [
        // {
        //   type: 'row',
        //   cols: 6,
        //   children: [
        //     {
        //       name: '活动日期',
        //       type: 'rangePicker',
        //       keys: ['useStartTime', 'useEndTime'],
        //       cols: 24,
        //       ranges: {
        //         最近一周: [moment(), moment().add(1, 'weeks')],
        //         最近一个月: [moment(), moment().add(1, 'month')],
        //         最近三个月: [moment(), moment().add(3, 'month')]
        //       },
        //       rules: [
        //         {
        //           required: true
        //         }
        //       ]
        //     }
        //   ]
        // },
        {
          name: 'Banner选择',
          type: 'radioButton',
          key: 'bannerType',
          cols: 12,
          defaultValue: '0',
          typeData: [
            {
              name: 'banner图片',
              value: '0',
              display: true,
            },
            {
              name: '视频地址',
              value: '2',
              display: true,
            },
            {
              name: '数字基地监控视频',
              value: '1',
              display: this.isShowSnapshot,
            },
          ].filter((e) => e.display),
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
      ]
    },
    showManageClassify(dataSource) {
      apiTool.showModal({
        width: '800px',
        title: '分类',
        success: ({ data, setHidden }) => {
          try {
            dataSource.forEach((e, i) => {
              if (!e.name || !e.sort) {
                throw new Error('请完善名称/排序！')
              }
            })
          } catch (error) {
            this.$message.warning('请完善名称/排序！')
            return false
          }
          let obj = {
            shopId: this.detail.linkShopId,
            ticketTypeList: dataSource.map((e) => {
              return {
                ...e,
                shopId: this.detail.linkShopId,
              }
            }),
          }
          postAction('/farmTicketType/saveBatch', obj).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功！')
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            type: 'table',
            wrapperCol: { span: 24 },
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                name: null,
                sort: 0,
                productCount: 0,
              }
            },
            columns: [
              {
                dataIndex: 'name',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>名称
                  </div>
                ),
                width: 240,
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.name = data.target.value
                  }
                  return <a-input placeholder="名称" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'sort',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>排序
                  </div>
                ),
                width: 100,
                customRender: function (text, records) {
                  const onInput = (data) => {
                    records.sort = data.target.value
                  }
                  return <a-input type="number" placeholder="" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'productCount',
                align: 'left',
                title: '已关联商品',
                width: 80,
                customRender: function (text, records) {
                  return <div>{text}</div>
                },
              },
              {
                align: 'center',
                width: '10%',
                title: '操作',
                tableType: 'editDelete',
                unShowEditBtn: true,
                disabledKey: 'productCount',
              },
            ],
            dataSource: dataSource,
          },
        ],
      })
    },
    getFormUp() {
      return {
        type: 'tabs',
        key: 'selectType',
        children: [
          {
            title: () => (
              <div>
                <span style="color:#f00">*</span>
                Banner
              </div>
            ),
            data: [
              {
                type: 'iconUpload',
                maxLength: 1,
                cols: 12,
                descTitle: 'Banner',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'bannerUrl',
                    desc: '<br /> 支持格式.png/.jpg',
                  },
                ],
                rules: [
                  {
                    required: true,
                    message: '请上传图片',
                    type: 'string',
                  },
                ],
              },
            ],
          },
          {
            title: '宣传视频',
            data: [
              {
                type: 'iconUploadVideo',
                cols: 12,
                descTitle: '宣传视频',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'videoUrl',
                    desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.linkShopId) : {}],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false,
      }
    },
    /**
 *   render: () => {
          return (
            <a-card title="退款设置" style="margin-top:10px;margin-bottom:-10px">
              <div class="boxes">
                <div class="boxes-one">
                  <div class="boxes-one-checkbox">
                    <a-checkbox class="">支持订单确认后，有效日期前（未验证情况下）退款</a-checkbox>
                  </div>
                  <div class="con">{this.renderTableList(1)}</div>
                </div>
                <div class="boxes-one">
                  <div class="boxes-one-checkbox">
                    <a-checkbox class="">支持有效期后（未验证情况下）退款</a-checkbox>
                  </div>
                  <div class="con">{this.renderTableList(2)}</div>
                </div>
                <div class="boxes-two">
                  <label>不可退款协议</label>
                  <a-input placeholder="不可退款协议" />
                </div>
              </div>
            </a-card>
          )
        }
 */
    onAddRule(index) {
      this.returnSet.push({})
    },
    renderTableList(index) {
      return (
        <div class="data-list-box">
          <div class="item-value">
            <span>有效日期：</span>
            <a-select default-value="lucy" style="width: 175px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-select default-value="lucy" style="width: 130px;margin-left:15px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <span>&nbsp;&nbsp;可退款&nbsp;&nbsp;</span>
            <a-select default-value="lucy" style="width: 215px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-input default-value="lucy" style="width: 88px;margin-left:15px" />
            <a-button type="danger" ghost style="margin-left: 15px; width:100px">
              删除
            </a-button>
          </div>
          <a-button type="primary" style="margin-top: 15px" onClick={() => this.onAddRule(index)}>
            添加退款规则
          </a-button>
        </div>
      )
    },
    getFormTksz() {
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款方式',
            type: 'radioButton',
            key: 'refundExplain',
            cols: 6,
            typeData: [
              {
                name: '不可退',
                value: '1',
              },
              // {
              //   name: '过期不可退',
              //   value: '2',
              // },
              {
                name: '随时退',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: this.detail.refundExplain !== '0',
            name: '不可退款协议',
            type: 'input',
            cols: 9,
            key: 'refundAgreement',
          },
          //   {
          //     display: true,
          //     name: '手续费开关',
          //     type: 'switchOpen',
          //     cols: 4,
          //     key: 'refundRateFlag',
          //     showText: ['开', '关']
          //   },
          //   {
          //     display: true,
          //     name: '退款手续费费率（%）',
          //     type: 'inputNumber',
          //     cols: 5,
          //     key: 'refundRate',
          //     props: {
          //       min: 0
          //     }
          //   }
        ].filter((e) => e.display),
      }
    },
    getLabelForm(index) {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName',
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2',
            },
            {
              name: '公用标签库',
              value: '1',
            },
          ],
        },
      ].filter((e) => e)
    },
    getForm4() {
      return {
        type: 'row',
        children: (this.labelGroup || []).map((e, i) => {
          return (
            <CardIconLabelLocal
              data={this.getLabelForm(i)}
              title={'产品标签'}
              pubBool={false}
              cols={24}
              style={{
                marginBottom: '10px',
                minHeight: '200px',
              }}
              icons={e.list}
            />
          )
        }),
      }
    },
    //#endregion 基础设置
    renderDisabled() {
      let val = this.detail.thirdTicket == '1' ? true : this.detail.canBuy == '0' ? true : false
      return val
    },
    //#region 价格设置
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            skuImgUrl: '',
            skuName: null,
            originalPrice: null,
            salePrice: null,
            jsPrice: null,
            allStock: null,
            sort: null,
          }
        },
        columns: [
          {
            title: '图片',
            type: 'lt-40',
            dataIndex: 'skuImgUrl',
            customRender: (text, records, index, h) => {
              return (
                <FormUploadSku
                  value={text}
                  disabled={that.renderDisabled()}
                  onChange={(data) => {
                    records.skuImgUrl = data
                  }}
                />
              )
            },
          },
          {
            dataIndex: 'skuName',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>产品规格
              </div>
            ),
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.skuName = data.target.value
              }
              return <a-input disabled={that.renderDisabled()} placeholder="产品规格" onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'originalPrice',
            align: 'left',
            title: '原价',
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.originalPrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>销售价
              </div>
            ),
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.salePrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="销售价"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'jsPrice',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>商家结算价
              </div>
            ),
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.jsPrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'allStock',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>库存
              </div>
            ),
            width: 100,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.allStock = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  placeholder="库存数量"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          //   {
          //     dataIndex: 'sort',
          //     align: 'left',
          //     title: (
          //       <div>
          //         <span style="color:#f5222d">*</span>排序
          //       </div>
          //     ),
          //     width: 100,
          //     customRender: function(text, records, index) {
          //       const onInput = data => {
          //         records.sort = data.target.value
          //       }
          //       return records.isEdit ? (
          //         <a-input
          //           disabled={that.detail.canBuy == '0'}
          //           type="number"
          //           placeholder="排序"
          //           onChange={onInput}
          //           value={text}
          //         />
          //       ) : (
          //         <div>{text}</div>
          //       )
          //     }
          //   }
          //   {
          //     align: 'center',
          //     width: '10%',
          //     title: '操作',
          //     tableType: 'editDelete',
          //     unShowEditBtn: true
          //   }
        ],
        dataSource: that.detail.skuList,
      }
    },
    getFormFysm() {
      return {
        title: '费用说明',
        type: 'cardForm',
        data: [
          {
            name: '费用包含',
            type: 'input',
            key: 'expenseContain',
            cols: 12,
          },
        ],
      }
    },
    getFormXnsj() {
      return {
        title: '虚拟数据',
        type: 'cardForm',
        data: [
          {
            name: '基础销量',
            type: 'inputNumber',
            key: 'saleCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            name: '基础浏览量',
            type: 'inputNumber',
            key: 'readCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          //   {
          //     name: '限购数量',
          //     type: 'inputNumber',
          //     key: 'buyCount',
          //     cols: 6,
          //     props: {
          //       min: 0
          //     }
          //   }
        ],
      }
    },
    //#endregion 价格设置

    //#region 商品详情
    getForm6() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            simulator: true,
            onWxClick: () => {
              apiTool.showModal({
                width: '800px',
                title: '微信图文采集',
                success: ({ data, setHidden }) => {
                  const { url } = data
                  postAction('/common/urlParse', { url }).then((res) => {
                    const { data } = res
                    this.detail.content = data
                    this.$forceUpdate()
                    setHidden()
                  })
                },
                formData: {},
                form: [
                  {
                    type: 'textCard',
                    text: () => {
                      return (
                        <div>
                          <p>
                            1.下方表单输入链接，可以采集微信内容链接。例如：https://mp.weixin.qq.com/s/Gm3npsJ880dngKPj1DWDjg
                          </p>
                          <p>
                            2.可以使用微信内容，135内容复制到编辑器中即可。由于图片采集需要时间，所以等待20秒再提交表单。
                          </p>
                          <p>温馨提示：视频，过于复杂的样式，背景图，可能会导致采集失败。</p>
                        </div>
                      )
                    },
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                  },
                  {
                    type: 'input',
                    key: 'url',
                    placeholder: '采集微信图文内容（填写微信图文链接）',
                    labelCol: { span: 0 },
                    wrapperCol: { span: 24 },
                    maxLength: 400,
                  },
                ],
                // foot: ({ close }) => {
                //   return (
                //     <Button onClick={close} type="primary">
                //       关闭
                //     </Button>
                //   )
                // },
              })
            },
            cols: 24,
          },
        ],
      }
    },
    //#endregion 商品详情

    //#region 高级设置
    getFormSphx() {
      return {
        title: '商品核销',
        type: 'cardForm',
        data: [
          {
            name: '有效期',
            type: 'radio',
            key: 'hxExpireType',
            cols: 24,
            typeData: [
              {
                name: () => {
                  const onInput = (data) => {
                    this.detail.hxAfterDay = data.target.value
                  }
                  return (
                    <span style="margin-right: 100px">
                      必须在购买后&nbsp;
                      <a-input
                        style={{ width: '70px' }}
                        type="number"
                        min={0}
                        placeholder=""
                        onChange={onInput}
                        value={this.detail.hxAfterDay}
                      />
                      &nbsp;天内使用
                    </span>
                  )
                },
                value: '1',
              },
              {
                name: () => {
                  return (
                    <span>
                      在购买后&nbsp;
                      <DatePicker.RangePicker
                        showTime={true}
                        ranges={{
                          最近一周: [moment(), moment().add(1, 'weeks')],
                          最近一个月: [moment(), moment().add(1, 'month')],
                          最近三个月: [moment(), moment().add(3, 'month')],
                        }}
                        allowClear={false}
                        onChange={this.onTimeChange}
                        value={this.getData()}
                        style={{ width: '400px' }}
                        placeholder={['开始日期', '结束日期']}
                      ></DatePicker.RangePicker>
                      &nbsp;时间段内都可使用
                    </span>
                  )
                },
                value: '5',
              },
            ],
          },
        ],
      }
    },
    getFormGmxz() {
      return {
        title: '购买限制',
        type: 'cardForm',
        data: [
          {
            display: true,
            type: 'row',
            cols: 12,
            children: [
              {
                name: '购买数量限制(限制条件:用户ID   不输入0代表不限制)',
                type: 'inputNumber',
                cols: 12,
                key: 'buyCount',
                props: {
                  min: 0,
                },
              },
            ],
          },
          {
            display: true,
            name: '生效时间限制',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '必须在购买后',
                cols: 3,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterDay',
                cols: 3,
                props: {
                  min: 0,
                },
              },
              {
                type: 'text',
                value: '天，',
                cols: 1,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterHour',
                cols: 3,
                props: {
                  min: 0,
                  max: 23,
                },
              },
              {
                type: 'text',
                value: '小时，才可使用',
                cols: 4,
              },
            ],
          },
          {
            display: true,
            name: '用户信息',
            type: 'checkBoxButton',
            key: 'realNameFlag',
            cols: 12,
            typeData: [
              {
                name: '实名制',
                value: '1',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '下单时填写信息',
            type: 'checkBoxButton',
            key: 'realNameInput',
            cols: 12,
            typeData: [
              {
                name: '姓名',
                value: 'link_man',
              },
              {
                name: '电话',
                value: 'link_phone',
              },
              {
                name: '身份证',
                value: 'link_credit_no',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '用户信息数量',
            type: 'radioButton',
            key: 'userInfoCount',
            cols: 12,
            typeData: [
              {
                name: '只填写一个人信息',
                value: 0,
              },
              {
                name: '根据下单数量填写所有用户信息',
                value: 1,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getFormTj() {
      return {
        title: '推荐',
        type: 'cardForm',
        data: [
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    getFormSysm() {
      return {
        title: '使用说明',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '换票',
            type: 'radioButton',
            key: 'changeTicket',
            cols: 12,
            typeData: [
              {
                name: '无需换票',
                value: '0',
              },
              {
                name: '换票使用',
                value: '1',
              },
            ],
          },
          {
            display: this.detail.changeTicket == 0,
            type: 'text',
            cols: 12,
          },
          {
            display: this.detail.changeTicket == 1,
            name: '换票地址',
            type: 'input',
            key: 'changeTicketAddress',
            cols: 12,
          },
          {
            display: true,
            name: '说明',
            type: 'input',
            key: 'changeTicketRemark',
            cols: 12,
          },
          {
            display: true,
            name: '使用地址',
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                label: '使用地址',
                type: 'input',
                key: 'useAddress',
                cols: 20,
              },
              {
                type: 'button',
                cols: 4,
                label: '填入的店铺地址',
                props: {
                  style: {
                    color: '#53aff4',
                    border: '1px solid #53aff4',
                  },
                  on: {
                    click: () => {
                      let addr = this.storeData.find((f) => f.id == this.detail.linkShopId)?.address || ''
                      if (addr) {
                        this.detail.useAddress = addr
                        this.$forceUpdate()
                      } else {
                        this.$message.warning('当前店铺暂无地址！')
                      }
                    },
                  },
                },
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    //#endregion 高级设置

    /** 方法 */
    handleData(info) {
      const { productName, img, marketPrice, salePrice, settlementPrice, chargeInclude, isSingle, userNote } = info
      this.detail = {
        ...this.detail,
        expenseContain: chargeInclude,
        userInfoCount: isSingle,
        changeTicketRemark: userNote,
        skuList: [
          {
            skuImgUrl: img,
            skuName: productName,
            originalPrice: marketPrice,
            salePrice: salePrice,
            jsPrice: settlementPrice,
            allStock: null,
          },
        ],
      }
      this.$forceUpdate()
    },
    getData() {
      return [this.detail.hxStartTime, this.detail.hxEndTime]
    },
    onTimeChange(times) {
      if (times.length > 0) {
        this.detail.hxStartTime = moment(times[0]).format('YYYY-MM-DD HH:mm:ss')
        this.detail.hxEndTime = moment(times[1]).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    getString(str) {
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    /** end 方法 */

    getFoot() {
      let that = this
      const left = [
        {
          name: '上一步',
          display: this.activeKey != 0 && this.detail.thirdTicket != 1,
          onClick: (data) => (this.activeKey = this.activeKey - 1),
        },
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            // 获取标签
            let arr = []
            this.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            this.labelGroup.map((e) => arr.push(...e.list))
            let brr = arr.map((e) => ({
              ...e,
              czState: e.czState ? 1 : 0,
            }))

            if (this.detail.thirdTicket == '0') {
              if (this.detail.hxExpireType == '1') {
                if (!this.detail.hxAfterDay) {
                  this.$message.warning('商品核销，有效期必填')
                  return false
                }
              }
              if (this.detail.hxExpireType == '5') {
                if (!this.detail.hxStartTime || !this.detail.hxEndTime) {
                  this.$message.warning('商品核销，有效期必填')
                  return false
                }
              }
            }

            let monitorCode = null
            let monitorImg = null
            if (data.monitorCode instanceof Array && data.bannerType == 1 && data.monitorCode.length > 0) {
              monitorCode = (
                data.monitorCode.find((e) => e.select) || {
                  monitorCode: '',
                }
              ).monitorCode
              monitorImg = (
                data.monitorCode.find((e) => e.select) || {
                  monitorImg: '',
                }
              ).monitorImg
            }
            let upDown = data.id ? data.upDown : '0'
            let obj = Object.assign({
              ...data,
              useStartTime: moment(data.useStartTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
              useEndTime: moment(data.useEndTime, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
              upDown,
              monitorCode,
              monitorImg,
              realNameFlag: data.realNameFlag && data.realNameFlag.length > 0 ? data.realNameFlag.join(',') : '0',
              realNameInput: data.realNameInput && data.realNameInput.length > 0 ? data.realNameInput.join(',') : '',
              farmLabelDataDTOS: brr,
            })

            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmTicket',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '下一步',
          display: this.activeKey != 3 && this.detail.thirdTicket != 1,
          onClick: (data) => {
            let nData = Object.assign({}, data, this.detail)
            if (this.activeKey == 0) {
              if (nData.thirdTicket == 0) {
                if (
                  !(nData.productName && nData.linkShopId && nData.thirdType && nData.productType && nData.bannerUrl)
                ) {
                  this.$message.warning('请完成表单填写')
                  return
                }
              } else if (nData.thirdTicket == 1) {
                if (
                  !(
                    nData.productName &&
                    nData.linkShopId &&
                    nData.thirdProductId &&
                    nData.productType &&
                    nData.bannerUrl
                  )
                ) {
                  this.$message.warning('请完成表单填写')
                  return
                }
              }
            }
            this.activeKey = this.activeKey + 1
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/goods/farmTicket/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
          display: this.detail.id,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmTicket?id=${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        return [
          this.getFormPz(),
          this.getForm1(),
          this.getFormUp(),
          this.isShowSnapshot && this.getForm2Copy(),
          this.detail.thirdTicket == '0' && this.getFormTksz(),
          this.detail.thirdTicket == '1' && this.getFormXnsj(),
          this.detail.thirdTicket == '1' && this.getFormTj(),
          this.getForm4(),
        ]
      } else if (this.activeKey === 1) {
        return [this.getFormFysm(), this.getFormXnsj()]
      } else if (this.activeKey === 2) {
        return [this.getForm6()]
      } else if (this.activeKey === 3) {
        return [this.getFormSphx(), this.getFormGmxz(), this.getFormTj(), this.getFormSysm()]
      }
    },
  },
  render() {
    return (
      <div>
        {this.detail.thirdTicket == '0' && (
          <Naves
            navData={['基础设置', '价格设置', '商品详情', '高级设置']}
            onActive={(index) => {
              this.activeKey = index
            }}
            activeCurrentKey={this.activeKey}
          />
        )}

        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            linkShopName: this.storeData,
            productTypeName: this.typeSearchData,
            thirdType: thirdTypeDataList(),
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
}

.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.boxes {
  .data-list-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &-two {
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #e8eaec;
    padding-top: 15px;
    box-sizing: border-box;

    input {
      width: 40%;
    }
  }
  &-one {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:nth-of-type(2) {
      border-top: 1px solid #e8eaec;
      box-sizing: border-box;
    }

    .con {
      width: 58%;
    }

    &-checkbox {
      width: 40%;

      & > :nth-child(1) {
        border: 1px solid #e8eaec;
        padding: 5px 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
